import axios from 'axios';
import config from "./../config";

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// intercepting to capture errors
axios.interceptors.response.use(function (response) {
    return response.data ? response.data : response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    switch (error.response.status) {
        case 500: 
            message = 'Erro Interno do Servidor';
            break;
        case 401:
            logoutUser();
            message = 'Credenciais Inválidas';
            break;
        case 404: 
            message = 'Desculpe! Os dados que você procura não puderam ser encontrados';
            break;

        default:
            if (error.response.status !== 500) {
                message = error.response.data.error.message;
            } else {
                message = 'Não foi possível completar a requisição; tente novamente mais tarde.';
            }
    }

    return Promise.reject(message);
});

const logoutUser = () => {
    localStorage.removeItem("authUser");
}

/**
 * Sets the default authorization
 * @param {*} token 
 */
const setAuthorization = (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

class APIClient {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        return axios.get(url, params);
    }

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axios.post(url, data);
    }

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.patch(url, data);
    }

    /**
     * Delete 
     */
    delete = (url) => {
        return axios.put(url);
    }
}

export { APIClient, setAuthorization, logoutUser };